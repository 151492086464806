
import { Component, Vue, Prop } from 'vue-property-decorator'
import MaintenanceDialog from '@/components/maintenanceDialog/Index.vue'
import { MaintenanceForm, MainInfo } from '@/types/common'
import { FileInfo } from '@/types/inspection'
import { colorRandom } from '@/utils/formatData'

@Component({
  name: 'MaintenanceRecord',
  components: { MaintenanceDialog },
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgList = list.map(item => {
        return item.filePrefix + item.fileUrl
      })
      return imgList || []
    }
  }
})
export default class MaintenanceRecord extends Vue {
  @Prop({ default: 2 }) private type!: number /** type 1  树木  养护    2 设施  维护 */
  @Prop({ default: true }) private requiredContent?: boolean /** 是否养护内容校验 */
  @Prop({ default: false }) private ordinarySeedlings?: boolean /** 是否苗木 */

  // 弹框名称
  private title = '维护记录'
  // 养护记录
  private recordList: Array<MaintenanceForm> = []

  private page = 1
  private size = 10
  private isLoading = false
  private isFinished = false

  // 编辑养护记录
  private showDialog = false
  // 维护记录id
  private recordId = ''
  private formData: MaintenanceForm | {} = {}
  private mainTypeList: MainInfo[] = []

  // seedlingId 普通苗木和古树名木传入
  // buildId 建筑设施
  // sceneryId 景观景点
  get objectId () {
    const objectId = this.$route.query.id || this.$route.params.buildId || this.$route.params.sceneryId || this.$route.params.seedlingId || ''
    return objectId
  }

  getTitle () {
    return this.type === 1 ? '养护档案' : '维护记录'
  }

  created () {
    if (this.objectId) {
      this.title = `添加${this.getTitle()}`
      this.getConfigList()
      this.getRecord()
    }
  }

  // 获取养护类型配置
  getConfigList () {
    this.$axios.get(this.$apis.record.selectConfigByList).then(res => {
      if (res) {
        this.mainTypeList = res.list || []
        this.mainTypeList.forEach((item) => {
          item.color = this.getTypeColor(item)
        })
      }
    })
  }

  // 获取养护档案列表
  getRecord () {
    this.isLoading = true
    this.$axios.get<{ total: number; list: Array<MaintenanceForm> }>(this.$apis.record.selectMaintainRecordByPage, {
      page: this.page,
      size: this.size,
      objectId: this.objectId
    }).then((res) => {
      this.page++
      this.recordList = res && res.list.length > 0 ? this.recordList.concat(res.list) : this.recordList
      this.isFinished = this.recordList.length === res.total
    }).finally(() => {
      this.isLoading = false
    })
  }

  onUpdate (activity: MaintenanceForm) {
    this.title = `编辑${this.getTitle()}`
    this.recordId = activity.recordId || ''
    this.formData = {
      maintainDate: activity.maintainDate,
      maintainContent: activity.maintainContent,
      resourceList: activity.resourceList || [],
      maintainStatus: activity.maintainStatus,
      chestDiameter: activity.chestDiameter,
      qualityLevel: activity.qualityLevel,
      valueAssessment: activity.valueAssessment,
      crownWidth: activity.crownWidth,
      treeHeight: activity.treeHeight,
      configId: activity.typeList ? this.getmaintainConfigId(activity.typeList) : []
    }
    this.showDialog = true
  }

  // 根据养护类型获取养护id
  getmaintainConfigId (list: MainInfo[]) {
    const configId: Array<string> = []
    list.forEach(item => {
      if (item && item.configId) {
        configId.push(item.configId)
      }
    })
    return configId
  }

  // 获取标签颜色
  getTypeColor (value: { typeName: string }) {
    if (value) {
      switch (value.typeName) {
        case '修剪':
          return '#9760E5FF'
        case '浇灌':
          return '#1AD1B0FF'
        case '施肥':
          return '#47A2FFFF'
        case '病害防治':
          return '#F3657CFF'
        case '虫害防治':
          return '#FBD44CFF'
        default: return colorRandom()
      }
    }
    return '#ffffff'
  }

  // 匹配当前类型颜色
  getCurrentColor (name: string) {
    return this.mainTypeList.filter(item => item.typeName === name)[0].color
  }

  onSubmit (formData: MaintenanceForm) {
    this.$axios.post(this.$apis.record.updateMaintainRecord, {
      ...formData,
      recordId: this.recordId
    }).then(() => {
      this.$message.success('保存成功')
      this.showDialog = false
      this.onSuccess()
    }).catch(() => {
      (this.$refs.maintenance as MaintenanceDialog).saveDisabledShow()
    })
  }

  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.record.deleteMaintainRecord, {
        recordId: id
      }).then(() => {
        this.$message({
          message: '删除成功',
          type: 'success'
        })
        this.onSuccess()
      })
    })
  }

  onSuccess () {
    this.$emit('update')
    this.page = 1
    this.recordList = []
    this.getRecord()
  }
}
